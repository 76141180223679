.cta {

	display: inline-block;
	width: 480px;
	max-width: 100%;

}


$cta-height: 48px;
$cta-small-height: 36px;


.cta__form {
	// @include xy-grid;
	// overflow: hidden;

	// @include breakpoint(medium){
	// 	box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.15);
	// }
	@include breakpoint(medium){
		height: $cta-height;
		display: flex;
	}
	
	.cta__input {
		// @include xy-cell(full, false);
		border: 1px solid $input-border-color;
		margin-bottom: .5em;
		border-radius: $border-radius;
		overflow: hidden;
		flex: 1;
		input {
			font-size: 1.2em;
			border: 0;
			width: 100%;
			// line-height: $cta-height;
			// height: $cta-height;
			text-align: center;

		    height: 100%;
		    text-align: center;
		    padding: 0.5em 0.75em;
		}

		@include breakpoint(medium) {
			// @include xy-cell(60%, false);
			border-right: 0;
			margin-bottom: 0;
			border-radius: $border-radius 0 0 $border-radius;

			input {
				text-align: left;
			}
		}
	}


	.cta__button {
		// @include xy-cell(full, false);
		// background-color: $primary-color;
		// border-radius: $border-radius;

		.btn-primary {
			width: 100%;
			// line-height: $cta-height;
			height: 100%;
			@include breakpoint(medium){
				box-shadow: none;
				border-radius: 0 $border-radius $border-radius 0;
			}
		}

		@include breakpoint(medium) {
			flex-shrink: 1 ;
		}
		
	}


}



.cta--small {
	.cta__form{
		height: $cta-small-height;
	}	
	
	.cta__input {
		input {
			font-size: 1em;
		    padding: 0.25em 0.75em;
			// line-height: $cta-small-height;
			// height: $cta-small-height;
		}
	}

	.cta__button {
			// line-height: $cta-small-height;
		.btn-primary {
		    padding: 0.25em 0.75em;
			// height: $cta-small-height;
			font-size: 1em;
		}
	}

}
