.margin-bottom-40 {margin-bottom: 40px}
.padding-top-20 {padding-top: 20px}

.theme-docs-section{
	.code-label{
		background: #ebeeee;
		padding: 7.5px 15px;
		margin-top: 20px;
		display: block;
		font-weight: bold;
	}
	.code-block{
		white-space: pre-wrap;
		background: #f3f5f5 ;
		padding: 1.875em;
		margin-top: 0px;
		margin-bottom: 20px;
		border: none; 
		b{
			font-weight: bold;
		}
	}
	.font-green{
		color: #008080;
		font-family: Menlo, Monaco, Consolas, "Lucida Console", monospace;
	}
	.font-orange{
		color: #d01040 ;
		font-family: Menlo, Monaco, Consolas, "Lucida Console", monospace;
	}
	code{
		background: transparent;
		padding: 0;
		border: none;
	}
}
