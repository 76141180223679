
@mixin label-variant($color,$text-color: 'rgba(#000, .5)') {
	background-color: $color;
	color: $text-color;
	&[href] {
		&:hover,
		&:focus {
			background-color: darken($color, 10%);
		}
	}
}


.label {
	display: inline;
	background-color: $primary-color;
	border-radius: 4px;
	line-height: 1;
	white-space: nowrap;
	padding: .3em .6em .3em;
	color: rgba(#000, .5);
	font-weight: normal;
	font-size: 75%;
	vertical-align: baseline;
}



.label-yellow {
	@include label-variant($label-color);
}

.label-primary {
	@include label-variant($primary-color, #fff);
}

.label-pink {
	@include label-variant(get-color(pink), #fff);
}
