.developer-intro{

	.developer-hero{
		background: url("/images/dev-hero-bg.svg") center center no-repeat;
		background-size: cover;
	}

	.dev-img{
		// box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		position: relative;
		top: -30px;
		transform: translateY(0px);
		// animation: float 6s ease-in-out infinite;
		// transition-timing-function: cubic-bezier(0.9, 0.01, 0.25, 1);
		@include breakpoint(small){
			height: 300px;
		}
		.img-dev{
			position: relative;
			span{
				position: absolute;
				max-width: 54%;
				left: 3%;
				animation: float 8s ease-in-out infinite;
				animation-timing-function: ease-in-out;
			}
			span:nth-child(1){
				top: 0px;
				z-index: 0;
				animation-delay: 0s;
			}
			span:nth-child(2){
				top: 30px;
				z-index: -1;
				animation-delay: 1s;

			}
			span:nth-child(3){
				top: 60px;
				z-index: -2;
				animation-delay: 2s;

			}
		}
		.img-ux{
			position: relative;
			span{
				position: absolute;
				max-width: 34%;
				right: 5%;
				animation: float 8s ease-in-out infinite;
				animation-timing-function: ease-in-out;
			}
			span:nth-child(1){
				top: 0px;
				z-index: 0;
				animation-delay: 1s;
			}
			span:nth-child(2){
				top: 30px;
				z-index: -1;
				animation-delay: 2s;

			}
			span:nth-child(3){
				top: 60px;
				z-index: -2;
				animation-delay: 3s;

			}
		}
	}

	.intro-card{
		text-decoration: none;
		display: block;
		font-weight: inherit;
		height: 100%;
		&:hover,&:focus{
			color: inherit;
			.card{
				border-bottom: solid 3px #4184f0;
			}
			.intro-link{
				color: $link-hover-color;
			}
		}
		.card{
			border-bottom: solid 3px transparent;
			height: 100%;
		}
		.intro-icon{
			max-width: 250px;
			margin: 0 auto 0.3em;
		}
		.intro-title{
			margin-bottom: 0.5em;
		}
		.card__section{
			display: flex;
			flex-direction: column;
			height: 100%;
			.intro-desc{
				flex: 1 0 auto;
			}
			
		}
	}

	.featured-img{
		max-width: 800px;
		margin: 0 auto;
	}

	.bg-footer{
		background-image: url(/images/partners/background.svg);
		background-size: cover;
		background-position: center bottom;
		background-repeat: no-repeat;
	}

}

@keyframes float {
	0% {
		// box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		// box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-10px);
	}
	100% {
		// box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}


.section-cta{
	.heading__head{
		margin-bottom: 0.2em;
	}

	.heading__subhead{
		margin-bottom: 0;
	}
}


.theme-doc{
	.theme-icon{
		max-width: 400px;
		margin: 0 auto;
	}
}
.doc-menu{
	border-right: 1px solid $border-color ;
	font-size: 0.9em;
	font-weight: 300;
	position: sticky;
	top: 90px;
	overflow-y: hidden;
	// height: 80vh;
	&:hover{
		overflow-y: auto;
	}
	.menu__link{
		padding: 8px 10px;
		color: $sub-heading-color;
		&.active{
			background-color: rgba(0,0,0,.05);
		}
		&:hover,&:focus{
			text-decoration: underline;
		}
	}

	.menu__item{
		padding: 0;
		&.menu--submenu{
			padding: 0;
			.menu__link{
				padding-left: 25px;
			}
		}
		
	}
	
	&.menu .menu{
		margin: 0;
	}
}
.doc-block{
	&~.doc-block{
		border-top: 1px solid $border-color;

		.block__heading{
			margin-top: 2em;
		}
	}

}

.doc-table{
	overflow-x: auto;
	table{
		border-top: 1px solid $table-border;
		border-left: 1px solid $table-border;
		border-spacing: 0;
		margin: 1em 0;
		width: 100%;
	}
	
	th,td{
		padding: 7.5px 10px;
		border-right: 1px solid $table-border;
		border-bottom: 1px solid $table-border;
		vertical-align: top;
	}

	th{
		// padding-top: .9em;
		// padding-bottom: .7em;
		text-align: left;
		background-color: $table-th-bg;
	}
}