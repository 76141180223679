
.nav{
	@include global-width;

		position: sticky;
		z-index: 1000;
		top: 0;

	&-grid {
		// @include global-padding;
		padding-left: 4%;
		padding-right: 4%;
		@include breakpoint(medium){
			padding-left: $grid-padding;
			padding-right: $grid-padding;
		}
		border-bottom: solid 1px $border-color;
		
		display: flex;
		justify-content: space-between;
	
		background-color: #fff;
		height: $nav-height;
	
		&__item {
			display: flex;
			align-items: center;

		}
	}

	&__menu {
		text-align: right;
		justify-content: flex-end;
		display: flex;
		align-items: center;
		flex: 1;

		&-logo {
			width: 180px;
			@include breakpoint(large){
				padding: 0 1em;
				width: 240px;
			}
			// line-height: $nav-height - 10px;
			// font-weight: normal;
			// padding: 5px 10px;
			// color: $text-color;
			// text-decoration: none;

		}

		.cta {
			display: inline-block;
			width: 300px;
			&.cta--button-only{
				width: auto;
				.btn-primary{
					padding: 0.5em 1.2em;
				}
			}
		}
	}

	&__mobile{
		&.active{
	    	transform: translateX(0);
	    	opacity: 1;
		}
		.cta{
			width: 100%;
			&.cta--button-only{
				.btn{
					display: block;
					width: 100%;
				}
			}
		}
		
		opacity: 0.3;
    	transform: translateX(100%);
    	transition: all cubic-bezier(.04,.97,.67,1.01) 300ms;
	    z-index: 1001;
	    background-color: #fff;
	    position: fixed;
	    // overflow: hidden;
	    overflow-x: hidden;
		overflow-y: scroll;
	    top: 0;
	    left: 0;
	    bottom: 0;
	    right: 0;
	    flex-direction: column;
	    &-body{
	    	padding: 1em 2rem 2rem;
			.menu__item{
				padding-left: 0;
				padding-right: 0;
			}
	    	.menu__link{
	    		font-size: 1.5em;
	    	}
	    }
	}
}

.burger{
	// display: none;
	z-index: 1100;
	position: fixed;
	top: 28px;
	right: 28px;
	padding: 2px;
	cursor: pointer;
	user-select: none;
	span{
		display: block;
		width: 24px;
		height: 4px;
		margin-bottom: 4px;
		position: relative;
		background: $sub-heading-color;
		border-radius: 1px;
		z-index: 1;
		transform-origin: 4px 0px;
		transition: all 0.35s cubic-bezier(0.77,0.2,0.05,1.0);
	}

	span:first-child{
		transform-origin: 0% 0%;
	}

	span:nth-last-child(2){
		transform-origin: 0% 100%;
	}
	&.active{
		span{
			margin-left: 30px;
			// opacity: 1;
			transform: rotate(45deg) translate(-8px, 8px);
			// background: rgb(240,240,240);
		}
		span:nth-child(3){
			opacity: 1;
			// transform: rotate(-45deg) translate(-9px, -12px);
			transform: rotate(-45deg) translate(-10px, -12px);
		}
		span:nth-child(2){
			opacity: 0;
			transform: rotate(0deg) scale(0.2, 0.2);
		}
	}

}
