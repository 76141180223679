/**
 v-clock for VueJS used.
 **/

[v-cloak] {
	display: none;
}

/**
 Base reset
 **/

*, *:after, *:before{
    outline: none;
    -webkit-overflow-scrolling: touch;
	-webkit-box-sizing: inherit;
    box-sizing: border-box;
}

// Firefox
button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring{
    outline: none;
}
button, html input[type=button], input[type=reset], input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer;
    line-height: 1.6;
}

* {
	
}

html,body {
	background-color: #fff;
	padding: 0;
	margin: 0;
	
	font-size: 16px;
	color: #222;
	line-height: 1.6;
	// padding-top: $nav-height * .8;
	font-weight: 300;
	// @include breakpoint(large) {
	// 	padding-top: $nav-height;
	// }
}
html[lang="zh-tw"],
html[lang="zh-tw"] body,
html[lang="zh-my"],
html[lang="zh-my"] body{
	font-weight: 400;
}

a, .anchor {
	color: $link-color;
	display: inline-block;
	text-decoration: underline;
	font-weight: normal;
	cursor: pointer;
	&:hover,&:focus {
		color: $link-hover-color;
	}
}

p{
	margin: 0;
	margin-bottom: 1em;
	color: $text-color;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	color: $heading-color;
	font-weight: $heading-font-weight;
}


table {
	border-spacing: 0;
	border-collapse: collapse;
}

ul,ol{
	padding-left: 20px;
	margin-bottom: 1em;
	margin-top: 0;
}


.body-overflow-hidden{
	overflow: hidden;
    height: 100%;
}

code, pre {
    font-family: Monaco, Consolas,Menlo,monospace;
    background-color: lighten($secondary-color,80%);;
    font-size: 15px;
}

p,li,td{
	&>code{
		&:before,&:after{
			content: '\00a0';
			letter-spacing: -0.125em;
		}
	}
}
