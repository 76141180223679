.testi-logo{
	margin-bottom: 1em;
}
.testi__card{
	height: 100%;

}
.testi__grid{
	margin-top: 3em;
}
.testi-statistics{
	// text-align: center;
	h5{
		text-transform: uppercase;
	}
}