.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.4em;
  line-height: 1.4em;
  &:before {
    content: '\00a0';
  }
}

$countries: ( my, us, tw, ph, th, hk, id );
@each $country in $countries {
  .flag-#{$country}{
    background-image: url("/images/countries/#{$country}.png");
  }
}
