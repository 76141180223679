.bg{
	&-grey{
		background: #F5F5F7;
	}
	&-gradient-grey-white{
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f5f5f7+0,ffffff+100 */
		background: #f5f5f7; /* Old browsers */
		background: -moz-linear-gradient(top, #f5f5f7 0%, #ffffff 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, #f5f5f7 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #f5f5f7 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f7', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
	}
	&-gradient-white-grey{
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,f5f5f7+100 */
		background: #ffffff; /* Old browsers */
		background: -moz-linear-gradient(top, #ffffff 0%, #f5f5f7 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, #ffffff 0%,#f5f5f7 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, #ffffff 0%,#f5f5f7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f7',GradientType=0 ); /* IE6-9 */
	}
}
		
.section {
	// @include global-padding;
	// text-align: center;
	padding: 3em 0;

	@include breakpoint(medium){
		padding-top: 6em;
		padding-bottom: 7em;
	}
	
	&--nav{
		padding-left: 2%;
		padding-right: 2%;
		border-bottom: 1px solid $border-color;
		background: white;
		.menu__item{
			padding-top: 0;
			padding-bottom: 0;
			.menu__link{
				padding-top: 0.8em;
				padding-bottom: 0.8em;
				color: $sub-heading-color;
				border-bottom: solid 3px transparent;
				transition: all ease 0.3s;
				&.active,&:hover{
					color: $text-color;
					border-bottom: solid 3px $text-color;
				}
			}
		}
	}
	// Section modifier
	&--narrow{
		padding: 2em 0;
		@include breakpoint(medium){
			padding-top: 4em;
			padding-bottom: 4em;
		}
	}

	&--line{
		border-top: 1px solid $border-color;
	}
	
	&--padding-top-only{
		padding-bottom: 0;
	}

	&--padding-bottom-only{
		padding-top: 0;
	}
	
	&--hero{
		padding-top: 3em;

		@include breakpoint(medium) {
			padding-top: 4em;
		}

		@include breakpoint(large) {
			padding-top: 6em;
		}

		.section__heading .heading__head{
			margin-bottom: 0.3em;
		}
	}

	// Element inside section
	&__heading {
		display: block;
		text-align: center;
		margin-bottom: 3em;
		
		@include breakpoint(large) {
			margin-bottom: 4em;
		}
		
		&--left{
			@include breakpoint(large) {
				text-align: left;
			}
		}

		&--narrow{
			margin-bottom: 2em;
		}

		.heading__head {
			display: block;
			// margin-bottom: .1em;
			margin-bottom: .5em;

			@include breakpoint(medium down) {
				// margin-bottom: .2em;
				margin-bottom: .3em;
			}
		}


		.heading__subhead {
			display: block;
			font-weight: normal;
			margin-bottom: 0.5em;
			color: $sub-heading-color;
		}
	}
}
