// for headings
.color{
	&-subhead{
		color: $sub-heading-color;
	}
}

.heading-1 {
	font-size: 2.8em;
	line-height: 1.1;
}

.heading-2 {
	font-size: 2em;
	line-height: 1.1;
}

.heading-3 {
	font-size: 1.6em;
	line-height: 1.1;
}

.heading-4 {
	font-size: 1.2em;
	line-height: 1.2;
}

.heading-5 {
	font-size: 1em;
	line-height: 1.2;
}



@include breakpoint(medium down) {

	.heading-1 {
		font-size: 2.4em;
	}
	
	.heading-2 {
		font-size: 1.9em;
		line-height: 1.2;
	}
	
	.heading-3 {
		font-size: 1.3em;
	}
	
	.heading-4 {
		font-size: 1.1em;
	}
	
	.heading-5 {
		font-size: 1em;
	}
	
}

// for text-size
.text-small{
	font-size: 85%;
}
.text-large{
	font-size: 110%;
}

