#Page,#Footer{
	@include global-width;
}
.footer {
	// @include global-padding;
	// padding-bottom: 1em;
	padding-top: 2em;

	
	.msc-logo{
		width: 80px;
		@include breakpoint(small){
			margin: 0 auto;
		}
	}

	&-heading {
		font-size: 18px;
		// font-weight: bold;
		margin-bottom: 0.5em;
	}
	&-no-heading{
		margin-top: 37px;
	}
	&-menu{
		margin-bottom: 1.5em;
		@include breakpoint(large){
			font-size: 90%;
		}
		li {
			margin: 0.6em 0;
			@include breakpoint(medium){
				margin: 5px 0;
			}
		}
		a {
			text-decoration: none;
			color: rgba(#000, .55);
			// line-height: 30px;

			&:hover {
				color: rgba(#000, 1);
			}
		}
		
	}
	&-social{
		@include breakpoint(small){
			justify-content: center;
		}
		li{
			margin-left: 0.5em;
			margin-right: 0.5em;
		}
		.social-icon{
			width: 25px;
			height: 25px;
			display: inline-block;
			vertical-align: baseline;
			fill: $sub-heading-color;
			&:hover{
				fill: $primary-color;
			}
		}
	}

}





.footer-grid {
	@include xy-grid;
	@include global-padding;
	margin-top: 2em;
	margin-bottom: 3em;
}


.footer-grid__item {
	@include grid-padding;
	
	@include xy-cell(1 of 2, false);

	@include breakpoint(medium) {
		@include xy-cell(1 of 3, false);
	}

	@include breakpoint(large) {
		@include xy-cell(25%, false);
	}

	.grid-x {
		@include grid-neg-margin;
		.cell{
			@include grid-padding;
		}
	}
	

}

.footer-copyright{
	font-size: 90%;
	padding-top: 1.5em;
	padding-bottom: 1.5em;
	display: flex;
    align-items: center;
	font-weight: normal;
    justify-content: center;
	
	@include breakpoint(large){
		justify-content: space-between;
	}

	.copyright{
		color: rgba(#000, .55);
	}
	.menu{
		flex-wrap: wrap
	}
	.dropdown-menu{
		@include breakpoint(medium down){
			left: 0;
			right: initial;
		}
	}
}
