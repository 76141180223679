@mixin global-width {
  // overflow: hidden;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

@mixin global-padding($type: "normal") {
  padding-left: 4%;
  padding-right: 4%;

  @if $type == "normal" {
    @include breakpoint(medium) {
      padding-left: 5%;
      padding-right: 5%;
    }

    @include breakpoint(large) {
      padding-left: 6%;
      padding-right: 6%;
    }
  } @else if $type == "narrow" {
    @include breakpoint(medium) {
      padding-left: 10%;
      padding-right: 10%;
    }

    @include breakpoint(large) {
      padding-left: 16%;
      padding-right: 16%;
    }
  }
}

@mixin grid-padding {
  padding-left: map-get($grid-cell-padding, small);
  padding-right: map-get($grid-cell-padding, small);
  @include breakpoint(medium) {
    padding-left: map-get($grid-cell-padding, medium);
    padding-right: map-get($grid-cell-padding, medium);
  }
}

@mixin grid-neg-margin {
  margin-left: 0 - map-get($grid-cell-padding, small);
  margin-right: 0 - map-get($grid-cell-padding, small);
  @include breakpoint(medium) {
    margin-left: 0 - map-get($grid-cell-padding, medium);
    margin-right: 0 - map-get($grid-cell-padding, medium);
  }
}

// text alignment
@mixin text-alignment {
  @each $align in (left, right, center, justify) {
    .text-#{$align} {
      text-align: $align;
    }
  }
}

@mixin table-border {
  $border-col: 1px solid #ddd;
  $border-row: 1px solid #f5f5f5;
  $border-radius: 6px;

  width: 100%;
  border: $border-col;
  border-radius: $border-radius;
  background-color: #fff;
  // box-shadow: 0 20px 100px rgba(#000, .05);

  > table {
    width: 100%;

    td {
      border-left: $border-col;
      border-bottom: $border-row;
    }

    td:first-child {
      border-left: 0;
    }

    tr:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}
