
.block {
	// display: block;
	// padding-bottom: 1em;
	margin-bottom: 2em;

	@include breakpoint(medium) {
		margin-bottom: 3em;
	}

	&__body{

	}

	&__heading {
		display: block;
		font-weight: normal;
		margin-bottom: .5em;
	}

	&__icon{
		margin: 0 auto 1em;
	}

	&-x{
		display: flex;
		.block__icon{
			flex: 1 1 auto;
			margin:0 1em 0 0;
		}
		.block__body{
			flex: 1;
		}
		&.block--align-center{
			align-items: center;
		}
	}
}












 .block--text-large {
 	font-size: 18px;
 }