//card element
.card{
	background: #ffffff;
    // margin: 1.6rem 1rem;
    border-radius: 6px;
    position: relative;
    box-shadow: $card-shadow;
    overflow: hidden;
    @include breakpoint(small){
        margin-bottom: 1em;
    }
    &__section{
    	&.padding--top-only{
    		padding-bottom: 0;
    	}
        &.section--line{
            border-top: 1px solid $border-color;
        }
    	padding: 2rem;
    }

}