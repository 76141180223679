.menu{
    list-style: none;
	display: flex;
	padding: 0;
    align-items: center;
    margin: 0;
	&__item{
		padding: 1rem;
	}
	&.vertical{
		flex-direction: column;
	    align-items: normal;
		.menu__link{
			line-height: 1;
			// padding: 10px;
		}
	}
	&__link{
		// color: $secondary-color;
		text-decoration: none;
		// line-height: 70px;
		display: block;
		&:hover{
			// color: $primary-color;
		}
	}
}