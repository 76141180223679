
$grid-padding-small: 1px;
.grid {
	@include xy-grid;
	@include global-padding;


	.grid__item {
		
		@include xy-cell(full);
		@include grid-padding;

	}
	
	// Nested grid support
	.grid{
		@include grid-neg-margin;
		padding-left: 0;
		padding-right: 0;
	}
	
	// Reverse
	&.grid--desktop-reverse{
		@include breakpoint(medium) {
			flex-direction: row-reverse;
		}
	}

	// grid size 1/3
	&.grid--desktop-sidebar {

		>.grid__item:nth-child(1) {

			@include breakpoint(large) {
				@include xy-cell(3 of 12);
			}
		}

		>.grid__item{
			
			@include breakpoint(large) {
			 	@include xy-cell(9 of 12);
			}
		}
		
	}
	
	// grid size 1/4
	&.grid--desktop-4 {

		>.grid__item {
			@include breakpoint(small){
				@include xy-cell(1 of 2);
			}

			@include breakpoint(medium) {
				@include xy-cell(1 of 3);
			}

			@include breakpoint(large) {
				@include xy-cell(1 of 4);
			}
		}
		
	}

	// grid size 1/3
	&.grid--desktop-3 {

		>.grid__item {

			@include breakpoint(medium) {
				@include xy-cell(1 of 2);
			}

			@include breakpoint(large) {
				@include xy-cell(1 of 3);
			}
		}
		
	}

	// grid size 1/2
	&.grid--desktop-2 {
		
		>.grid__item {
			@include breakpoint(medium) {
				@include xy-cell(1 of 2);
			}

		}
		
	}
	
	// grid size 9/12
	&.grid--desktop-narrow{

		@include flex-align($x: center, $y: stretch);

		>.grid__item{

			@include breakpoint(medium) {
				@include xy-cell(5 of 6);
			}

			@include breakpoint(large) {
				@include xy-cell(9 of 12);
			}
		}
	}
	
	// grid size 11/12
	&.grid--desktop-wide{

		@include flex-align($x: center, $y: stretch);

		>.grid__item{

			@include breakpoint(large) {
				@include xy-cell(11 of 12);
			}

		}
	}
}



