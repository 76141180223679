.dropdown{
	position: relative;
	&.active{
		>.dropdown-menu{
			// display: block;
			opacity: 1;
			height: auto;
			padding: 5px 0;
		}
		.arrow{
			transform: rotate(-180deg);
		}
	}
	&-menu{
		background-color: #fff;
		position: absolute;
	    bottom: 100%;
	    right: 0;
	    z-index: 1000;
	    overflow: hidden;
	    // display: none;
	    height: 0;
	    opacity: 0.3;
	    float: left;
	    min-width: 170px;
	    padding: 0;
	    margin: 2px 0 0;
	    border-radius: 4px;
	    transition: $global-transition;
    	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	
	}
	&-btn{
		.arrow{
			width: 10px;
			height: 10px;
			display: inline-block;
			vertical-align: baseline;
			transition: all ease 0.3s;
		}
	}
	.menu__link{
		white-space: nowrap;
		.flag-icon{
			margin-right: 3px;
		}
	}
}